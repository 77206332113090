import React from "react";
import { navigate } from "gatsby";
import { getRedirectLanguage } from "../lib/shared";


export default () => {

    // server side redirect is configured in staticwebapp.config.json
    React.useEffect(() => {
      const urlLang = getRedirectLanguage()
      navigate(`/${urlLang}/`, {replace: true})
    }, [])


}
